<template>
  <div class="auth-wrapper auth-v1 align-start py-0">
    <div class="auth-inner">
      <v-card-text>
        <v-row class="px-0 py-1 ma-0">
          <v-col
            cols="12"
            class="py-1 px-2 d-flex justify-space-between align-center"
          >
            <div class="d-flex justify-space-between align-center">
              <v-img
                :src="appLogoTitle"
                max-width="120px"
                alt="logo"
                contain
                class="me-3 cursor-pointer"
                @click="$router.push('/')"
              ></v-img>
            </div>

            <div>
              <v-btn
                class="ma-0 py-2 px-1 no-bg-hover font-weight-bold"
                style="
                  text-transform: inherit;
                  min-width: 60px;
                "
                text
                color="secondary"
                @click="defaultDownloadAppByOS"
              >
                {{ t('videos.download_app') }}
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text>
        <v-row class="px-0 py-1 ma-0">
          <v-col
            cols="12"
            class="mt-14 mb-5 py-1 px-2 d-flex justify-center align-center"
          >
            <v-carousel
              width="auto"
              height="250px"
              class="rounded-lg"
              hide-delimiters
              show-arrows-on-hover
              cycle
              :show-arrows="false"
            >
              <v-carousel-item
                v-if="videoData.video && videoData.video.thumbnail && !videoData.video.thumbnail.includes('no-thumb')"
                :src="videoData.video.thumbnail"
                eager
                class="bg-grey"
              >
                <v-chip
                  class="absolute-chip text-xs"
                  color="#1d1d1dd4"
                  small
                >
                  {{ videoData.video ? videoData.video.video_time : '00:00' }}
                </v-chip>
              </v-carousel-item>

              <div v-else-if="videoData.video && videoData.video.thumbnails && videoData.video.thumbnails.some(e => !e.includes('no-thumb'))">
                <v-carousel-item
                  v-for="(item, index) in videoData.video.thumbnails.filter(e => !e.includes('no-thumb'))"
                  :key="index"
                  :src="item"
                  eager
                  class="bg-grey"
                >
                  <v-chip
                    class="absolute-chip text-xs"
                    color="#1d1d1dd4"
                    small
                  >
                    {{ videoData.video ? videoData.video.video_time : '00:00' }}
                  </v-chip>
                </v-carousel-item>
              </div>
              <v-carousel-item
                v-else
                eager
                class="bg-grey"
              >
                <v-chip
                  class="absolute-chip text-xs"
                  color="#1d1d1dd4"
                  small
                >
                  {{ videoData.video ? videoData.video.video_time : '00:00' }}
                </v-chip>
              </v-carousel-item>
            </v-carousel>
          </v-col>
        </v-row>

        <v-row class="mb-4 px-4 py-1 ma-0">
          <v-col
            cols="12"
            class="py-1 px-2 d-flex justify-center align-center"
          >
            <h3 class="black--text">
              {{ formatVideoDay }}
            </h3>
          </v-col>
          <v-col
            cols="12"
            class="py-1 px-2 d-flex justify-center align-center"
          >
            <span class="black--text">{{ formatVideoData }}</span>
          </v-col>
        </v-row>

        <v-row class="my-5 px-4 py-1 ma-0">
          <v-col
            cols="12"
            class="py-1 px-2 d-flex justify-center align-center"
          >
            <v-btn
              class="text-capitalize"
              style="height: 42px !important"
              color="secondary"
              dark
              rounded
              block
              @click="onWatch"
            >
              {{ t('videos.watch_recording') }}
            </v-btn>
          </v-col>
        </v-row>

        <v-row
          class="ma-0 mb-4 mx-4 px-0 py-5"
          style="borderTop: 1px solid #D9D9D9"
        >
          <v-col
            cols="12"
            class="py-1 px-2 d-flex justify-center align-center"
          >
            <h3 class="gray--text text-sm font-weight-regular">
              {{ t('videos.dont_have') }}
            </h3>
          </v-col>
          <v-col
            cols="12"
            class="py-1 px-2 d-flex justify-center align-center"
          >
            <v-btn
              class="ma-0 pa-0 px-1 no-bg-hover font-weight-bold"
              style="
                text-transform: inherit;
                min-width: 60px;
                height: 12px !important;
              "
              text
              color="secondary"
              @click="defaultDownloadAppByOS"
            >
              {{ t('videos.download_app') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </div>

    <download-app
      :is-open.sync="isOpen"
      @open-close="openUpdate"
      @save="defaultDownloadAppByOS"
    />
  </div>
</template>

<script>
/* eslint-disable import/no-cycle */
/* eslint-disable no-lonely-if */
import { ref, computed, onMounted } from '@vue/composition-api'
import { mdiClose } from '@mdi/js'
import { useUtils } from '@core/libs/i18n'
import {
  formatCurrency,
  useRouter,
  isAndroid,
  isIOS,
  openTab,
  defaultDownloadAppByOS,
  isDev,
} from '@core/utils'
import themeConfig from '@themeConfig'
import moment from 'moment'
import { getRecordingsHashid } from '@api'
import useVideoData from '@core/utils/useVideoData'

import DownloadApp from '@/views/reservation/components/download-app/DownloadApp.vue'

export default {
  components: {
    DownloadApp,
  },
  setup() {
    const { t } = useUtils()
    const { router, route } = useRouter()
    const { videoData, currentVideoData, updateVideoData } = useVideoData()

    const deepLinkVideo = ref('fanaty://video_detail/')
    const deepLinkVideoIOS = ref(`${isDev.value ? 'https://dev-app.fanaty.com' : 'https://app.fanaty.com'}/video_detail/`)

    const isOpen = ref(false)
    const isAndroidComputed = computed(() => isAndroid())
    const isIOSComputed = computed(() => isIOS())

    const formatVideoDate = videoDate => {
      const strDate = moment(videoDate).format('MMM DD')

      return strDate
    }

    const formatVideoDay = computed(() => {
      if (videoData.value && videoData.value.video) {
        if (videoData.value.video.register_date) return moment(`${videoData.value.video.register_date} ${videoData.value.video.video_start_time}`, 'YYYY-MM-DD hh:mm').format('dddd HH:mm')

        return moment(`${videoData.value.video.video_date} ${videoData.value.video.start_time}`, 'YYYY-MM-DD hh:mm').format('dddd HH:mm')
      }

      return ''
    })

    const formatVideoData = computed(() => {
      if (videoData.value && videoData.value.video) return `${formatVideoDate(videoData.value.video.register_date)} • ${videoData.value.video.facility_name} • ${videoData.value.video.field_name}`

      return ''
    })

    const openUpdate = async val => {
      isOpen.value = val
    }

    const onWatch = () => {
      const dLink = `${deepLinkVideo.value}${videoData.value.video.video_id}`

      const dLinkIOS = `${deepLinkVideoIOS.value}${videoData.value.video.video_id}`
      if (isAndroid() || isIOS()) {
        if (isIOS()) {
          if (isDev.value) alert(`deepLinkVideoIOS value: ${dLinkIOS}`)

          // if (target && target === 1) openTab(dLinkIOS)
          // if (target && target === 2) window.location = dLinkIOS
          // if (target && target === 3) window.open().location = dLinkIOS
          // else openTab(dLinkIOS)

          openTab(dLinkIOS)
        } else {
          window.location = dLink
          isOpen.value = true
        }
      } else isOpen.value = true
    }

    const getHash = url => url.split('/r/')[1]

    const fetchRecordingHash = async hash => {
      const resp = await getRecordingsHashid(hash)
      if (resp.ok) {
        if (resp.data.video_redirect_url) {
          const hashRedirect = getHash(resp.data.video_redirect_url)
          const respRedirect = await getRecordingsHashid(hashRedirect)
          if (respRedirect.ok) {
            videoData.value.video = respRedirect.data
            router.replace({ name: 'recording', params: { hash: hashRedirect } })
          }
        } else {
          if (resp.data.video_inactive || resp.data.video_invalid) {
            router.push({
              name: 'video-not-available',
              params: {
                inactive: !!resp.data.video_inactive,
                invalid: !!resp.data.video_invalid,
              },
            })
          } else {
            Object.assign(videoData.value, currentVideoData.value)

            if (!videoData.value.video) videoData.value.video = resp.data
            else {
              const url = videoData.value.video.video_share_url ? videoData.value.video.video_share_url : videoData.value.video.share_video_url
              const isNewHash = url && getHash(url) !== hash
              if (isNewHash) videoData.value.video = resp.data
            }

            if (!videoData.value.video.video_time) videoData.value.video.video_time = resp.data.video_time

            if (!videoData.value.video.check_redirect) {
              videoData.value.video.check_redirect = false
              videoData.value.price = resp.data.video_price
              videoData.value.match = null
              videoData.value.paymentMethodsParams = null
              await updateVideoData(videoData.value)

              if (videoData.value.video.show_video_type) router.push({ name: 'views-video-quality' })
            }
          }
        }
      }
    }

    onMounted(async () => {
      const { hash } = route.value.params
      if (hash) await fetchRecordingHash(hash)
      else Object.assign(videoData.value, currentVideoData.value)
    })

    return {
      // data
      isOpen,
      videoData,

      deepLinkVideo,
      deepLinkVideoIOS,

      // methods
      formatCurrency,
      formatVideoData,
      formatVideoDay,
      defaultDownloadAppByOS,
      fetchRecordingHash,
      onWatch,
      openUpdate,

      isAndroidComputed,
      isIOSComputed,

      // themeConfig
      appLogoTitle: themeConfig.app.logoTitleBlack,

      t,

      icons: {
        mdiClose,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/pages/view.scss';
@import '@core/preset/preset/pages/landing.scss';
</style>
<style lang="scss" scoped>
  .absolute-close {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #222222;
    color: #ffffff6b;
    border-radius: 50px !important;
    padding: 2px;
  }

  .border-img {
    border-radius: 10px;
  }

  .text-mail {
    color: #FF3232;
    font-size: 0.875rem;
    font-weight: 500;
    letter-spacing: 0.01875rem;
    text-decoration: none;
    white-space: nowrap;
  }

  .bg-grey {
    background-color: #979797;
  }
</style>
